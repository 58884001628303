import { gql } from "graphql-request";
import { ErrorMetadata, ID } from "../../Types";

export const SAVE_PROJECT_APPLICATION_MUTATION = gql`
  mutation SaveProjectApplicationCategoryMutation(
    $projectId: ID!
    $designCategoryId: ID!
    $name: String!
    $linkedProjectDesignIds: [ID!]
  ) {
    projectApplicationCategorySave(
      input: {
        projectId: $projectId
        designCategoryId: $designCategoryId
        name: $name
        linkedProjectDesignIds: $linkedProjectDesignIds
      }
    ) {
      projectApplicationCategory {
        id
        name
        projectId
        designCategory {
          id
          name
        }
      }
      errors {
        title
        message
      }
    }
  }
`;

export type SaveProjectApplicationCategoryMutationDTO = {
  projectApplicationCategorySave: {
    projectApplicationCategory: {
      id: string;
      name: string;
      projectId: string;
      designCategory: {
        id: string;
        name: string;
      };
    };
  } & ErrorMetadata;
};

export type SaveProjectApplicationCategoryMutationParams = {
  projectId: ID;
  designCategoryId: ID;
  name: string;
  linkedDesigns?: ID[];
};

export const DELETE_PROJECT_APPLICATION_MUTATION = gql`
  mutation DeleteProjectApplicationMutation($designCategoryId: ID!) {
    projectApplicationCategoryDelete(input: { id: $designCategoryId }) {
      projectApplicationCategory {
        id
      }
      errors {
        title
        message
      }
    }
  }
`;

export type DeleteProjectApplicationMutationDTO = {
  projectApplicationCategoryDelete: {
    projectApplicationCategory: {
      id: string;
    };
  } & ErrorMetadata;
};

export type DeleteProjectApplicationMutationParams = {
  designCategoryId: ID;
};

export const UPDATE_PROJECT_SPECIFICATION_MUTATION = gql`
  mutation UpdateProjectSpecificationMutation(
    $projectId: ID!
    $designCategoryId: ID!
    $templateResponses: JSON
    $unitSystem: UnitSystem
  ) {
    projectApplicationCategorySave(
      input: {
        projectId: $projectId
        designCategoryId: $designCategoryId
        templateResponses: $templateResponses
        unitSystem: $unitSystem
      }
    ) {
      projectApplicationCategory {
        id
      }
      errors {
        title
        message
      }
    }
  }
`;

export type UpdateProjectSpecificationMutationDTO = {
  projectApplicationCategorySave: {
    projectApplicationCategory: {
      id: ID;
    };
  } & ErrorMetadata;
};

export type UpdateProjectSpecificationMutationParams = {
  projectId: ID;
  designCategoryId: ID;
  templateResponses: any;
  unitSystem: string;
};

export const UPDATE_PROJECT_SPECIFICATION_TYPE_MUTATION = gql`
  mutation UpdateProjectSpecificationTypeyMutation(
    $projectId: ID!
    $fromDesignCategoryId: ID!
    $toDesignCategoryId: ID!
  ) {
    projectApplicationCategoryMove(
      input: {
        projectId: $projectId
        fromDesignCategoryId: $fromDesignCategoryId
        toDesignCategoryId: $toDesignCategoryId
      }
    ) {
      clientMutationId
      projectApplicationCategory {
        id
      }
      errors {
        message
        title
      }
    }
  }
`;

export type UpdateProjectSpecificationTypeMutationDTO = {
  projectApplicationCategoryMove: {
    clientMutationId: string;
    projectApplicationCategory: {
      id: ID;
    };
  } & ErrorMetadata;
};

export type UpdateProjectSpecificationTypeMutationParams = {
  projectId: ID;
  fromDesignCategoryId: ID;
  toDesignCategoryId: ID;
};
